import { __decorate, __metadata } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import Box from '@/shared/layout/box/layout-box.vue';
import UIInput from '@/shared/ui/forms/input/ui-input.vue';
import FileUpload from '@/shared/ui/forms/file-upload/file-upload.vue';
import GET_S3_URLS from './get-s3-urls.graphql';
let S3MultiUploader = class S3MultiUploader extends Vue {
    location;
    accept;
    filesMap = {};
    status = null;
    filesCompleted = 0;
    getLocation(file) {
        return typeof this.location === 'string' ? this.location : this.location(file);
    }
    async upload(files) {
        if (files.length === 0)
            return;
        this.status = 'preparing';
        Array.from(files).forEach(file => {
            let location = this.getLocation(file);
            const key = location + '/' + file.name;
            this.$set(this.filesMap, key, {
                name: file.name,
                location,
                file,
                type: file.type,
                url: null,
                uploadedPercentage: 0
            });
        });
        this.updateProgress();
        const response = await this.$apollo.getClient().query({
            query: GET_S3_URLS,
            fetchPolicy: 'network-only',
            variables: {
                keys: Object.entries(this.filesMap).map(([key, file]) => ({
                    key,
                    fileType: file.type
                }))
            }
        });
        if (!response || !response.data || !response.data.s3UploadUrls) {
            this.status = 'failed';
            return this.updateProgress();
        }
        response.data.s3UploadUrls.map(fileResult => {
            this.filesMap[fileResult.key].url = fileResult.url;
        });
        this.status = 'uploading';
        this.updateProgress();
        Object.entries(this.filesMap).forEach(([key, file]) => {
            const xhr = new XMLHttpRequest();
            xhr.upload.addEventListener('progress', event => {
                this.$set(this.filesMap[key], 'uploadedPercentage', Math.round((event.loaded / event.total) * 100));
                this.updateProgress();
            });
            xhr.upload.addEventListener('load', () => {
                this.filesMap[key].uploadedPercentage = 100;
                this.filesCompleted++;
                this.updateProgress();
            }, { once: true });
            xhr.upload.addEventListener('error', () => {
                this.filesMap[key].uploadedPercentage = 0;
                this.filesCompleted++;
                this.updateProgress();
            }, { once: true });
            xhr.open('PUT', file.url, true);
            xhr.send(file.file);
        });
    }
    updateProgress() {
        const files = Object.values(this.filesMap);
        if (files.length === this.filesCompleted) {
            this.status = 'uploaded';
        }
        const result = {
            files,
            status: this.status
        };
        this.$emit('input', result);
    }
};
__decorate([
    Prop({ required: true, type: [String, Function] }),
    __metadata("design:type", Object)
], S3MultiUploader.prototype, "location", void 0);
__decorate([
    Prop({ required: false, type: String }),
    __metadata("design:type", Object)
], S3MultiUploader.prototype, "accept", void 0);
S3MultiUploader = __decorate([
    Component({
        components: {
            FileUpload,
            Box,
            UIInput
        }
    })
], S3MultiUploader);
export default S3MultiUploader;
